import store from '@store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@views/Login.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/resetPwd',
        name: 'resetPwd',
        component: () => import('@views/ResetPwd.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/',
        component: () => import('@layouts/Main.vue'),
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@views/Home.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'documents',
                name: 'documents',
                component: () => import('@views/ged/GedView.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'search-documents',
                name: 'searchDocuments',
                component: () => import('@views/ged/GedSearchView.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'forecast/list',
                name: 'forecastList',
                component: () => import('@views/forecast/ForecastList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'forecast/create',
                name: 'forecastCreate',
                component: () => import('@views/forecast/ForecastCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'forecast/:id/update',
                name: 'forecastUpdate',
                component: () => import('@views/forecast/ForecastUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'forecast/:id/details',
                name: 'forecastDetails',
                component: () => import('@views/forecast/ForecastDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'link/list',
                name: 'linkList',
                component: () => import('@views/link/LinkList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'link/create',
                name: 'linkCreate',
                component: () => import('@views/link/LinkCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'link/:id/update',
                name: 'linkUpdate',
                component: () => import('@views/link/LinkUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'link/:id/details',
                name: 'linkDetails',
                component: () => import('@views/link/LinkDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'summary/daily/classic/list',
                name: 'classicDailySummaryList',
                component: () => import('@views/summary/ClassicDailySummaryList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'summary/daily/modernized/list',
                name: 'modernizedDailySummaryList',
                component: () => import('@views/summary/ModernizedDailySummaryList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'summary/weekly/list',
                name: 'weeklySummaryList',
                component: () => import('@views/summary/WeeklySummaryList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/condtravail/list',
                name: 'bilanCondTravailList',
                component: () => import('@views/bilan/condtravail/BilanCondTravailList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/condtravail/synthese',
                name: 'bilanCondTravailSynthese',
                component: () => import('@views/bilan/condtravail/BilanCondTravailSynthese.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/condtravail/create',
                name: 'bilanCondTravailCreate',
                component: () => import('@views/bilan/condtravail/BilanCondTravailCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/condtravail/:id/update',
                name: 'bilanCondTravailUpdate',
                component: () => import('@views/bilan/condtravail/BilanCondTravailUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/condtravail/:id/details',
                name: 'bilanCondTravailDetails',
                component: () => import('@views/bilan/condtravail/BilanCondTravailDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/list',
                name: 'bilanCriseList',
                component: () => import('@views/bilan/crise/BilanCriseList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/synthese',
                name: 'bilanCriseSynthese',
                component: () => import('@views/bilan/crise/BilanCriseSynthese.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/create',
                name: 'bilanCriseCreate',
                component: () => import('@views/bilan/crise/BilanCriseCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/period/create',
                name: 'bilanCrisePeriodCreate',
                component: () => import('@views/bilan/crise/BilanCrisePeriodCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/:id/update',
                name: 'bilanCriseUpdate',
                component: () => import('@views/bilan/crise/BilanCriseUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/period/:id/update',
                name: 'bilanCrisePeriodUpdate',
                component: () => import('@views/bilan/crise/BilanCrisePeriodUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/:id/details',
                name: 'bilanCriseDetails',
                component: () => import('@views/bilan/crise/BilanCriseDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/crise/period/:id/details',
                name: 'bilanCrisePeriodDetails',
                component: () => import('@views/bilan/crise/BilanCrisePeriodDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/formation/list',
                name: 'bilanFormationList',
                component: () => import('@views/bilan/formation/BilanFormationList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/formation/synthese',
                name: 'bilanFormationSynthese',
                component: () => import('@views/bilan/formation/BilanFormationSynthese.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/formation/create',
                name: 'bilanFormationCreate',
                component: () => import('@views/bilan/formation/BilanFormationCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/formation/:id/update',
                name: 'bilanFormationUpdate',
                component: () => import('@views/bilan/formation/BilanFormationUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/formation/:id/details',
                name: 'bilanFormationDetails',
                component: () => import('@views/bilan/formation/BilanFormationDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/materiel/list',
                name: 'bilanMaterielList',
                component: () => import('@views/bilan/materiel/BilanMaterielList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/materiel/synthese',
                name: 'bilanMaterielSynthese',
                component: () => import('@views/bilan/materiel/BilanMaterielSynthese.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/materiel/create',
                name: 'bilanMaterielCreate',
                component: () => import('@views/bilan/materiel/BilanMaterielCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/materiel/:id/update',
                name: 'bilanMaterielUpdate',
                component: () => import('@views/bilan/materiel/BilanMaterielUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/materiel/:id/details',
                name: 'bilanMaterielDetails',
                component: () => import('@views/bilan/materiel/BilanMaterielDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/circuit/list',
                name: 'bilanCircuitList',
                component: () => import('@views/bilan/circuit/BilanCircuitList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/circuit/synthese',
                name: 'bilanCircuitSynthese',
                component: () => import('@views/bilan/circuit/BilanCircuitSynthese.vue'),
                meta: {
                    authRequired: true,
            
                },
            },
            {
                path: 'bilan/circuit/create',
                name: 'bilanCircuitCreate',
                component: () => import('@views/bilan/circuit/BilanCircuitCreate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/circuit/:id/update',
                name: 'bilanCircuitUpdate',
                component: () => import('@views/bilan/circuit/BilanCircuitUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'bilan/circuit/:id/details',
                name: 'bilanCircuitDetails',
                component: () => import('@views/bilan/circuit/BilanCircuitDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'intervention/list',
                name: 'interventionList',
                component: () => import('@views/intervention/InterventionList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'intervention/:id/details',
                name: 'interventionDetails',
                component: () => import('@views/intervention/InterventionDetails.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'intervention/:id/update',
                name: 'interventionUpdate',
                component: () => import('@views/intervention/InterventionUpdate.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'intervention/create',
                name: 'interventionCreate',
                component: () => import('@views/intervention/InterventionUpdate.vue'),
                meta: {
                    authRequired: true,
                    creation: true,
                },
            },
            {
                path: 'actualite',
                name: 'actualiteList',
                component: () => import('@views//actualite/ActualiteList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/ager',
                name: 'agerList',
                component: () => import('@views/admin/ager/AgerList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/uer',
                name: 'uerList',
                component: () => import('@views/admin/uer/UerList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/cei',
                name: 'ceiList',
                component: () => import('@views/admin/cei/CeiList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/cei/agents',
                name: 'ceiAgentList',
                component: () => import('@views/admin/user/CeiAgentList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/circuit',
                name: 'circuitList',
                component: () => import('@views/admin/circuit/CircuitList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/userGroup',
                name: 'userGroupList',
                component: () => import('@views/admin/usergroup/UserGroupList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/user',
                name: 'userList',
                component: () => import('@views/admin/user/UserList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'admin/axeintervention',
                name: 'axeInterventionList',
                component: () => import('@views/admin/axeintervention/AxeInterventionList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'help',
                name: 'help',
                component: () => import('@views/help/help.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'unauthorized',
                name: 'unauthorized',
                component: require('@views/core/_unauthorized.vue').default,
                // Allows props to be passed to the 404 page through route
                // params, such as `resource` to define what wasn't found.
                props: true,
            },
        ]
    },
    {
        path: '/404',
        name: '404',
        component: require('@views/core/_404.vue').default,
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '404',
    },
]